import { Box, Heading } from "@biblioteksentralen/react";
import { Translate, useTranslation } from "../../../../utils/hooks/useTranslation";
import { SearchResult } from "../SearchContext";
import { SearchState } from "../useSearchState";
import { CategorySearch } from "./CategorySearch";
import { ContentSearchCategory } from "./searchers/searchContent";
import { useContentSearch } from "./useContentSearch";

export const yourLibrarySearchCategories = [
  "events",
  "digitalLibraryServices",
  "libraries",
  "recommendations",
  "services",
  "employees",
  "staticPages",
] as const satisfies ContentSearchCategory[];

const getYourLibrarySearchCategoryNameMap = (t: Translate["t"]): Record<ContentSearchCategory, string> => ({
  events: t("Arrangementer"),
  digitalLibraryServices: t("Digitalt bibliotek"),
  libraries: t("Bibliotek"),
  recommendations: t("Anbefalinger"),
  services: t("Tjenester og tilbud"),
  employees: t("Ansatte"),
  staticPages: t("Informasjon"),
});

export const useYourLibrarySearch = (
  searchState: SearchState,
  setSearchState: (searchState: NonNullable<SearchState>) => void
): SearchResult => {
  const { t } = useTranslation();

  const { children, isLoading, categories } = useContentSearch({
    pageSize: 4,
    searchers: [
      "digitalLibraryServices",
      "employees",
      "events",
      "libraries",
      "recommendations",
      "services",
      "staticPages",
    ],
    includeCatogeries: true,
    searchState,
    setSearchState,
  });

  const summary = children && (
    <Box>
      <Heading as="h2" fontSize="2xl" margin="0 0 1rem">
        {t("Ditt bibliotek")}
      </Heading>
      {children}
    </Box>
  );

  return {
    summary,
    categoryResults: categories?.map((category) => ({
      id: category,
      label: getYourLibrarySearchCategoryNameMap(t)[category],
      children: (
        <CategorySearch key={category} category={category} searchState={searchState} setSearchState={setSearchState} />
      ),
    })),
    areSearchResultsLoading: isLoading,
    areCategoriesLoading: isLoading,
  };
};
