import { groq } from "next-sanity";
import { resolveStaticPageSummary } from "../../../../../components/staticPage/sanityQuery";
import { ContentSearcher } from "./searchContent";

export const searchStaticPages: ContentSearcher = {
  filter: (locale) => groq`
  *[_type == "staticPage"]
    | score(
        boost(title.${locale} match $searchQuery, 10),
        boost(pt::text(body.${locale}) match $searchQuery, 1),
    )
    [_score > 0]
`,
  resolver: groq`_type == "staticPage" => {${resolveStaticPageSummary}}`,
};
