import(/* webpackMode: "eager", webpackExports: ["ClientSideInfoPage"] */ "/vercel/path0/apps/frontend/src/app/[locale]/sites/[siteDomain]/info/[id]/page.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/@sanity+next-loader@1.2.3_next@14.2.5_@babel+core@7.23.3_@opentelemetry+api@1.7.0_@playwright_6qtonqlvjbhv6sxqqbvsupm4uq/node_modules/@sanity/next-loader/dist/client-components/live-stream.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/@sanity+next-loader@1.2.3_next@14.2.5_@babel+core@7.23.3_@opentelemetry+api@1.7.0_@playwright_6qtonqlvjbhv6sxqqbvsupm4uq/node_modules/@sanity/next-loader/dist/client-components/live.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-sanity@9.8.25_@sanity+client@6.24.1_@sanity+icons@3.2.0_react@18.3.1__@sanity+types@3.47_t73oixryqxaoy3bxpbdftqojny/node_modules/next-sanity/dist/visual-editing/client-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/common/src/isInsideAnIframe.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/common/src/useIsIframeReady.ts");
