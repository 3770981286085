import { groq } from "next-sanity";
import { ContentSearcher } from "./searchContent";
import { FrontendLocale } from "@libry-content/localization";
import { resolveListSummary, resolveRecommendationSummary } from "@libry-content/common";

// Gir mulighet til å gi treff ved søk på nøkkelord som ikke finnes i dataen
const customMatchStrings: Record<FrontendLocale, string> = {
  nb: "anbefalinger leseforslag inspirasjon",
  nn: "anbefalinger leseforslag inspirasjon",
};

export const searchRecommendations: ContentSearcher = {
  filter: (locale) => groq`
  *[_type in ["list", "recommendation"]]
    | score(
        boost(title.${locale} match $searchQuery, 10),
        boost(publication.title match $searchQuery, 10),
        boost(_type in ["list", "recommendation"] && "${customMatchStrings[locale]}" match $searchQuery, 5), // _type in ["list", "recommendation"] is a hack to make content lake give an appropriate score (without it we always get a score of 1)
        boost(publication.author match $searchQuery, 5),
        boost(teaser.${locale} match $searchQuery, 5),
        boost(description.${locale} match $searchQuery, 3),
        boost(pt::text(description.${locale}) match $searchQuery, 1),
    )
    [_score > 0]
`,
  resolver: groq`
    _type == "recommendation" => {${resolveRecommendationSummary}},
    _type == "list" => {${resolveListSummary}}
  `,
};
