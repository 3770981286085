import { Collapse } from "@biblioteksentralen/react";
import { useEffect, useReducer, useRef } from "react";
import { HeaderSearchInput } from "../../../searchAndCollection/components/search/HeaderSearchInput";
import { SearchViewInput } from "../../../searchAndCollection/components/search/SearchViewInput";
import { useIsOnSearchPage } from "../../../searchAndCollection/hooks/useIsOnSearchPage";

type Props = {
  defaultOpen: boolean;
};

export const useSearchInput = (props?: Props) => {
  const isSearchPage = useIsOnSearchPage();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const defaultOpen = props?.defaultOpen || isSearchPage;
  const [isOpen, dispatch] = useReducer((state: boolean, action: "toggle" | "open" | "close") => {
    const newState = action === "toggle" ? !state : action === "open";
    if (newState === true) {
      setTimeout(() => inputRef.current?.focus(), 0); // Set focus on input when opening
    }
    return newState;
  }, defaultOpen);

  // Makes sure search is default open on frontpage and search page, and default closed on other pages
  useEffect(() => {
    defaultOpen ? dispatch("open") : dispatch("close");
  }, [defaultOpen]);

  const SearchComponent = isSearchPage ? SearchViewInput : HeaderSearchInput;

  const input = (
    <Collapse in={isOpen} unmountOnExit animateOpacity>
      <SearchComponent id="catalogSearchDesktop" inputRef={inputRef} />
    </Collapse>
  );

  return {
    isOpen,
    toggle: () => dispatch("toggle"),
    input,
  };
};
