"use client";

import { DefaultPageLayout } from "../../../../../../components/layout/DefaultPageLayout";
import StaticPageView from "../../../../../../components/staticPage/StaticPageView";
import { SanityDataWithPreviewConfig } from "../../../../../sanityFetcher/getAppFolderPageProps";
import { SanityPreviewLiveQuery } from "../../../../../sanityFetcher/SanityPreviewLiveQuery";
import { InfoPageData } from "./page";

type Props = {
  sanityData: SanityDataWithPreviewConfig<InfoPageData>;
};

export const ClientSideInfoPage = (props: Props) => (
  <DefaultPageLayout>
    <SanityPreviewLiveQuery sanityData={props.sanityData}>
      {(data) => <StaticPageView {...data} />}
    </SanityPreviewLiveQuery>
  </DefaultPageLayout>
);
