import { groq } from "next-sanity";
import { ContentSearcher } from "./searchContent";
import { FrontendLocale } from "@libry-content/localization";
import { resolveEmployeeSummaryGroqProjection } from "@libry-content/common";

// Gir mulighet til å gi treff ved søk på nøkkelord som ikke finnes i dataen
const customMatchStrings: Record<FrontendLocale, string> = {
  nb: "ansatte tilsette medarbeidere",
  nn: "ansatte tilsette medarbeidere",
};

export const searchEmployees: ContentSearcher = {
  filter: (locale) => groq`
  *[_type == "employee" && showEmployee]
    | score(
        boost(name match $searchQuery, 10),
        boost(_type == "employee" && "${customMatchStrings[locale]}" match $searchQuery, 5), // _type == 'employee' is a hack to make content lake give an appropriate score (without it we always get a score of 1)
        boost(position.${locale} match $searchQuery, 5),
    )
    [_score > 0]
`,
  resolver: groq`_type == "employee" => {${resolveEmployeeSummaryGroqProjection}}`,
};
