import { DocumentType, Language, Platform, Work } from "@biblioteksentralen/cordata";
import { Box, Button, Flex, StackProps, useBoolean } from "@biblioteksentralen/react";
import { sortByMultiple } from "@biblioteksentralen/utils";
import { useState } from "react";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import {
  LanguagesSignature,
  formatLanguagesList,
  getLanguagesSignature,
  getSortedLanguagesListIndex,
  languageListsEqual,
} from "../../cordata/languages";
import { getRelevantManifestations } from "../../cordata/manifestations";
import { useSearchConfig } from "../../hooks/useSearchConfig";
import { LibraryInfo } from "../../sanityQueries";
import { HoldingText } from "../HoldingText";
import { ManifestationStatusForUser } from "../manifestations/ManifestationStatusForUser";
import { useManifestationStatusForUser } from "../manifestations/useManifestationStatusForUser";
import { FindInLibraryModal } from "../works/FindInLibraryModal";
import { useWorkViewData } from "../works/WorkViewBanner/useWorkViewData";
import { ReservationButton } from "./ReservationButton";
import { ReservationContainer, reservationFullWidthBreakpoint } from "./ReservationContainer";
import { ReservationLanguageMenu, getDocumentTypesInLanguage } from "./ReservationLanguageMenu";
import { ReservationVariantButtons, WorkVariant } from "./ReservationVariantButtons";
import { isInteractiveWork } from "../../cordata/works";
import { VariantParameters } from "../works/WorkViewBanner/useCurrentVariantCharacteristics";
import { ExternalContentService } from "./externalContentService";
import { getLanguagesForDocumentType } from "./MovieReservation";
import { getFormatOrderIndex } from "@libry-content/integrations";

const getWorkVariants = (work: Work, languages: Language[][]): WorkVariant[] => {
  const unSortedVariants = languages.flatMap((languagesList) =>
    getDocumentTypesInLanguage(work, getLanguagesSignature(languagesList)).map((documentType) => ({
      languagesList,
      documentType,
      platforms: getPlatformsForVariant(work, documentType, languagesList),
    }))
  );

  return sortByMultiple(
    unSortedVariants,
    ({ documentType }) => getFormatOrderIndex(documentType.format),
    ({ languagesList }) => getSortedLanguagesListIndex(languagesList)
  );
};

const getPlatformsForVariant = (
  work: Work,
  documentType: DocumentType,
  languages: Language[]
): Platform[] | undefined =>
  work.expressions?.find(
    (expression) =>
      expression.languages.every((expressionLanguage) =>
        languages.find((language) => language.code === expressionLanguage.code)
      ) && expression.manifestations?.find((manifestation) => documentType.code === manifestation.documentType?.code)
  )?.platforms;

type ReservationProps = {
  work: Work;
  libraries: LibraryInfo[];
} & StackProps;

export const Reservation = ({ work, libraries, ...stackProps }: ReservationProps) => {
  const { t } = useTranslation();
  const [findInLibraryModalOpen, { on: openFindInLibraryModal, off: closeFindInLibraryModal }] = useBoolean();
  const { isSearchIntegrationEnabled } = useSearchConfig();
  const {
    languages,
    currentVariantState,
    updateCurrentVariantState,
    currentLanguagesList,
    currentLanguagesSignature,
    currentDocumentType,
    representativeManifestation,
  } = useWorkViewData(work);

  const relevantManifestations = getRelevantManifestations(work, currentLanguagesList, currentDocumentType);

  const currentDocumentTypeCode = currentDocumentType?.code;

  const manifestationIds = relevantManifestations.map(({ id }) => id);
  const manifestationStatusForUser = useManifestationStatusForUser(relevantManifestations);

  const [languagesBeingSelected, setLanguagesBeingSelected] = useState<LanguagesSignature | undefined>();
  const isSelectingLanguage = !!languagesBeingSelected && currentVariantState?.language !== currentLanguagesSignature;

  const [documentTypeCodeBeingSelected, setDocumentTypeCodeBeingSelected] = useState<string | undefined>();
  const isSelectingDocumentType =
    !!documentTypeCodeBeingSelected && currentVariantState?.type !== currentDocumentTypeCode;

  const onSelectVariant = (
    languagesSignature?: LanguagesSignature,
    documentTypeCode?: DocumentType["code"],
    externalContentServiceOriginName?: ExternalContentService["originName"]
  ) => {
    if (
      languageListsEqual(currentLanguagesList, languagesSignature) &&
      (!documentTypeCode || documentTypeCode === currentDocumentType?.code)
    ) {
      return; // No change
    }

    const documentTypes = getDocumentTypesInLanguage(work, languagesSignature);

    const variantParameterss = getNewVariantParameters(
      work,
      documentTypes,
      currentDocumentTypeCode,
      languagesSignature,
      documentTypeCode,
      externalContentServiceOriginName
    );

    if (variantParameterss.type) setDocumentTypeCodeBeingSelected(variantParameterss.type);
    if (variantParameterss.language) setLanguagesBeingSelected(variantParameterss.language);

    updateCurrentVariantState(variantParameterss);
  };

  const loading = isSelectingLanguage || isSelectingDocumentType || !manifestationStatusForUser.isReady;

  const workVariants = getWorkVariants(work, languages);

  const someLanguageLabelsAreLong = workVariants.some(
    ({ languagesList }) => (formatLanguagesList(languagesList ?? [])?.length ?? 0) > 10
  );

  // Use language menu if there is more than three options total
  // or if the langauge labels are more than + characters and there are more than one option total

  const useLanguageMenu = workVariants.length > 3 || (someLanguageLabelsAreLong && workVariants.length > 1);

  return (
    <ReservationContainer documentType={currentDocumentType} {...stackProps}>
      <Box width="100%" position="relative">
        <form aria-label="form">
          {useLanguageMenu && (isInteractiveWork(work) ? languages.length > 1 : true) && (
            <ReservationLanguageMenu
              work={work}
              isDisabled={isSelectingLanguage || isSelectingDocumentType}
              languages={languages}
              currentLanguagesList={currentLanguagesList}
              onSelectVariant={onSelectVariant}
            />
          )}

          {workVariants.length > 0 && (
            <ReservationVariantButtons
              useLanguageMenu={useLanguageMenu}
              currentLanguagesList={currentLanguagesList}
              currentDocumentType={currentDocumentType}
              onSelectVariant={onSelectVariant}
              workVariants={
                useLanguageMenu
                  ? workVariants.filter(({ languagesList }) => languageListsEqual(languagesList, currentLanguagesList))
                  : workVariants
              }
              isDisabled={isSelectingDocumentType || isSelectingLanguage}
              marginBottom={{ base: "1rem", [reservationFullWidthBreakpoint]: "1.25rem" }}
            />
          )}

          {!manifestationStatusForUser.isLoanedByUser && !manifestationStatusForUser.isReservedByUser && (
            <HoldingText
              work={work}
              manifestationIds={manifestationIds}
              marginBottom="0.5rem"
              justifyContent="center"
              alignItems="center"
              textProps={{ fontWeight: "normal", fontSize: "md" }}
              separateLines
            />
          )}

          <ManifestationStatusForUser
            manifestationStatusForUser={manifestationStatusForUser}
            documentType={currentDocumentType}
            marginBottom="0.5rem"
          />

          <ReservationButton
            work={work}
            manifestations={relevantManifestations}
            manifestationStatusForUser={manifestationStatusForUser}
            representativeManifestation={representativeManifestation}
            loading={loading}
            height="3rem"
          />
        </form>

        {isSearchIntegrationEnabled && (
          <Flex justifyContent="center" marginTop="0.75rem">
            <Button variant="secondary" width="100%" height="3rem" fontSize="lg" onClick={openFindInLibraryModal}>
              {t("Finn i biblioteket")}
            </Button>
          </Flex>
        )}
      </Box>

      <FindInLibraryModal
        isOpen={findInLibraryModalOpen}
        onClose={closeFindInLibraryModal}
        representativeManifestation={representativeManifestation}
        manifestationIds={manifestationIds}
        work={work}
        libraries={libraries}
        isRepresentedWork
      />
    </ReservationContainer>
  );
};

export const getNewVariantParameters = (
  work: Work,
  documentTypes: DocumentType[],
  currentDocumentTypeCode: string | undefined,
  languagesSignature?: LanguagesSignature,
  documentTypeCode?: DocumentType["code"],
  externalContentServiceOriginName?: ExternalContentService["originName"]
): VariantParameters => {
  const languagesForDocumentType = documentTypeCode ? getLanguagesForDocumentType(work, documentTypeCode) : [];
  const autoSelectedlanguagesSignature = languagesForDocumentType[0]
    ? getLanguagesSignature(languagesForDocumentType[0])
    : undefined;
  const languagesParameterNoLanguagesSignature =
    currentDocumentTypeCode && !documentTypes.some(({ code }) => code === currentDocumentTypeCode)
      ? { language: autoSelectedlanguagesSignature }
      : {};

  const languagesParameter = languagesSignature
    ? { language: languagesSignature }
    : languagesParameterNoLanguagesSignature;

  const documentTypeParameter = documentTypeCode
    ? { type: documentTypeCode }
    : languagesParameter
    ? //Reset document type code parameter to default when changing language
      { type: documentTypes[0]?.code }
    : {};

  return {
    isbn: undefined,
    manifestationId: undefined,
    ...documentTypeParameter,
    ...languagesParameter,
    externalContentService: externalContentServiceOriginName,
  };
};
