import { FrontendLocale } from "@libry-content/localization";
import { groq } from "next-sanity";
import { resolveLibraryGroqProjection } from "../../../../../components/library/sanityQuery";
import { ContentSearcher } from "./searchContent";

// Gir mulighet til å gi treff ved søk på nøkkelord som ikke finnes i dataen
const customMatchStrings: Record<FrontendLocale, string> = {
  nb: "bibliotek åpningstider åpner åpent opnar opent opningstider kontaktinfo addressen epost email e-post e-mail telefonnummer mobilnummer rullestol parkering",
  nn: "bibliotek åpningstider åpner åpent opnar opent opningstider kontaktinfo addressen epost email e-post e-mail telefonnummer mobilnummer rullestol parkering",
};

export const searchLibraries: ContentSearcher = {
  filter: (locale) => groq`
    *[_type == "library"] |
      score(
        boost(_type == "library" && "${customMatchStrings[locale]}" match $searchQuery, 50), // _type == 'library' is a hack to make content lake give an appropriate score (without it we get a score of 1 even when we set boost to 50)
        boost(name.${locale} match $searchQuery, 15),
        boost(teaser.${locale} match $searchQuery, 5),
      )
      [_score > 0]
  `,
  resolver: groq`_type == "library" => {${resolveLibraryGroqProjection}}`,
};
