import { FrontendLocale } from "@libry-content/localization";
import { groq } from "next-sanity";
import { resolveServiceSummary } from "../../../../../components/services/sanityQuery";
import { ContentSearcher } from "./searchContent";

// Gir mulighet til å gi treff ved søk på nøkkelord som ikke finnes i dataen
const customMatchStrings: Record<FrontendLocale, string> = {
  nb: "tilbud tjenester",
  nn: "tilbud tjenester",
};

export const searchServices: ContentSearcher = {
  filter: (locale) => groq`
  *[_type == "service"]
    | score(
        boost(title.${locale} match $searchQuery, 10),
        boost(_type == "service" && "${customMatchStrings[locale]}" match $searchQuery, 5), // _type == "service" is a hack to make content lake give an appropriate score (without it we always get a score of 1)
        boost(teaser.${locale} match $searchQuery, 5),
        boost(pt::text(body.${locale}) match $searchQuery, 1),
    )
    [_score > 0]`,
  resolver: groq`
    _type == "service" => {${resolveServiceSummary}}
  `,
};
