import { CordataSearchAgentsResponse } from "@biblioteksentralen/bmdb-search-client";
import { useState } from "react";
import { Box, List, SimpleGrid } from "@biblioteksentralen/react";
import { VisuallyHiddenResults } from "../../../components/VisuallyHiddenResults";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { useAgentsData } from "../../hooks/useAgentsData";
import { AgentPreview } from "../agents/AgentPreview";
import { SeeMoreResultsButton } from "./SeeMoreResultsButton";

type Props = {
  agents: CordataSearchAgentsResponse["results"];
  endOfResults: boolean;
  isValidating: boolean;
  paginate: () => void;
};

const SearchResultsAgent = ({ agents, endOfResults, isValidating, paginate }: Props) => {
  const { t } = useTranslation();
  const { externalData: agentsExternalData, works: agentsWorks } = useAgentsData(agents);

  const [autoFocusIndex, setAutoFocusIndex] = useState<number | undefined>(undefined);

  const paginateAndFocus = () => {
    //sets focus on first item of next pagination
    setAutoFocusIndex(agents.length);
    paginate();
  };

  if (agents.length === 0) return null;

  return (
    <Box as="section">
      <VisuallyHiddenResults itemsLength={agents.length} name={agents.length > 1 ? t("personer") : t("person")} />
      <SimpleGrid as={List} spacing="2rem" width="100%" columns={{ base: 1, sm: 2, md: 3, lg: 4 }}>
        {agents.map((agent, index) => (
          <AgentPreview
            key={agent.id}
            agent={agent}
            externalData={agentsExternalData?.[agent.identifiers.norafId ?? ""]}
            works={agentsWorks[agent.id]}
            autoFocus={index == autoFocusIndex}
          />
        ))}
      </SimpleGrid>
      {!endOfResults && <SeeMoreResultsButton marginTop="2rem" loading={isValidating} onClick={paginateAndFocus} />}
    </Box>
  );
};

export default SearchResultsAgent;
