import { Spinner } from "@biblioteksentralen/react";
import { SearchState } from "../useSearchState";
import { ContentSearchCategory } from "./searchers/searchContent";
import { useContentSearch } from "./useContentSearch";

export const CategorySearch = (props: {
  category: ContentSearchCategory;
  searchState: SearchState;
  setSearchState: (searchState: NonNullable<SearchState>) => void;
}) => {
  const { children, isLoading } = useContentSearch({
    pageSize: 8,
    searchers: [props.category],
    searchState: props.searchState,
    setSearchState: props.setSearchState,
  });

  if (isLoading) {
    <Spinner margin="3rem auto" size="xl" />;
  }

  return children;
};
