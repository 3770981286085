import {
  getNorwegianDateNow,
  getNorwegianTimeNow,
  ResolvedEmployeeSummary,
  ResolvedEventSummary,
  ResolvedListSummary,
  ResolvedRecommendationSummary,
  scopeQueryToSite,
} from "@libry-content/common";
import { FrontendLocale } from "@libry-content/localization";
import { groq } from "next-sanity";
import { ResolvedDigitalLibraryServiceSummary } from "../../../../../components/digitalLibrary/sanityQuery";
import { ResolvedLibrary } from "../../../../../components/library/sanityQuery";
import { ResolvedServiceSummary } from "../../../../../components/services/sanityQuery";
import { ResolvedStaticPageSummary } from "../../../../../components/staticPage/sanityQuery";
import { sanityClient } from "../../../../../utils/sanity/client";
import { defaultSizes } from "../../../../searchApi/constants";
import { CommonSearchRequestSchema } from "../../../../types";
import { searchDigitalLibraryServices } from "./searchDigitalLibraryServices";
import { searchEmployees } from "./searchEmployees";
import { searchEvents } from "./searchEvents";
import { searchLibraries } from "./searchLibraries";
import { searchRecommendations } from "./searchRecommendations";
import { searchServices } from "./searchServices";
import { searchStaticPages } from "./searchStaticPages";

export type SearchContentRequestData = CommonSearchRequestSchema & {
  siteDomain?: string;
  languageCode: FrontendLocale;
  searchers: ContentSearchCategory[];
  includeCatogeries?: boolean;
};

export const searchContent = async (config: SearchContentRequestData): Promise<SearchContentResponse> => {
  const { searchQuery, siteDomain, size = defaultSizes.content, languageCode, from = 0 } = config;

  if (!searchQuery || !siteDomain || !languageCode) return { items: [], endOfResults: true };

  const params = {
    today: getNorwegianDateNow(),
    timeNow: getNorwegianTimeNow(),
    siteDomain,
    searchQuery: searchQuery.split(/\s/g).map((word) => `*${word}*`), // To apply some fuzzy search. Splitting query on whitespace and appending and prepending with "*". Etc "Hello world" => ["*Hello*", "*world*"]
    size: size + 1, // size + 1 for å kunne vurdere om vi skal vise "vis flere"-knapp. vi brukte før "total" å vurdere om vi skulle vise "vis flere"-knapp, men det er ingen elegant måte å hente ut "total" uten å doble størreslen på groq-spørringen som fører til store kall og dobbelt arbeid på serveren til sanity
    from,
  };

  const searchContnetCatogeriesQuery = getSearchContentCategoryHitsQuery(languageCode);
  const searchContentQuery = getSearchContentQuery(languageCode, config.searchers);

  const query = scopeQueryToSite(groq`{
    "items": ${searchContentQuery},
    ${config.includeCatogeries ? `"categoryHits": ${searchContnetCatogeriesQuery}` : ""}
  }`);

  const response = await sanityClient.fetch(query, params);

  return {
    ...response,
    items: response.items.slice(0, size), // Må fjerne det siste elementet som vi hentet for å vurdere om vi skulle vise "vis flere"-knapp
    endOfResults: response.items.length <= size,
  };
};

export type ContentSearcher = {
  filter: (locale: FrontendLocale) => string;
  resolver: string;
};

const contentSearchers = {
  events: searchEvents,
  digitalLibraryServices: searchDigitalLibraryServices,
  libraries: searchLibraries,
  recommendations: searchRecommendations,
  services: searchServices,
  employees: searchEmployees,
  staticPages: searchStaticPages,
} as const;

export type ContentSearchCategory = keyof typeof contentSearchers;

export type SearchContentResponse = {
  items: ContentSearchItem[];
  categoryHits?: CategoryHits;
  endOfResults: boolean;
};

export type ContentSearchItem =
  | ResolvedEventSummary
  | ResolvedDigitalLibraryServiceSummary
  | ResolvedLibrary
  | ResolvedRecommendationSummary
  | ResolvedListSummary
  | ResolvedServiceSummary
  | ResolvedEmployeeSummary
  | ResolvedStaticPageSummary;

const getSearchContentQuery = (locale: FrontendLocale, contentSearcherKeys: ContentSearchCategory[]) => {
  const searchers = Object.entries(contentSearchers)
    .filter(([key]) => contentSearcherKeys.includes(key as ContentSearchCategory))
    .map(([, value]) => value);

  return groq`[
    ${searchers.map(({ filter }) => `...${filter(locale)}`).join(", ")}
  ]
  | order(_score desc, _createdAt desc) [$from...($from + $size)]
  {
    ...select(
      ${searchers.map(({ resolver }) => resolver).join(", ")}
      {...} // Fallback
    )
  }
  `;
};

type CategoryHits = Record<ContentSearchCategory, boolean>;

const getSearchContentCategoryHitsQuery = (locale: FrontendLocale) => groq`{
  "events": defined(${searchEvents.filter(locale)}[0]),
  "digitalLibraryServices": defined(${searchDigitalLibraryServices.filter(locale)}[0]),
  "libraries": defined(${searchLibraries.filter(locale)}[0]),
  "recommendations": defined(${searchRecommendations.filter(locale)}[0]),
  "services": defined(${searchServices.filter(locale)}[0]),
  "employees": defined(${searchEmployees.filter(locale)}[0]),
  "staticPages": defined(${searchStaticPages.filter(locale)}[0]),
}`;
