import { z } from "zod";
import { SearchCategory } from "./SearchContext";
import { useMultipleUrlStates } from "../../../utils/hooks/useMultipleUrlStates/useMultipleUrlStates";
import { agentSearchCategory } from "./useAgentSearch";
import { yourLibrarySearchCategories } from "./content/useYourLibrarySearch";
import { allSearchFacets, workSearchCategories, WorkSearchFacet } from "./types";

const searchFacetSchema = z.array(z.string()).optional();
type SearchFacetSchemasType = Record<WorkSearchFacet, typeof searchFacetSchema>;

const searchFacetSchemas: SearchFacetSchemasType = allSearchFacets.reduce(
  (acc, facetName) => ({ ...acc, [facetName]: searchFacetSchema }),
  {} as SearchFacetSchemasType
);

const workSearchParamsSchema = z.object({ pagination: z.number().optional(), ...searchFacetSchemas }).optional();
const agentSearchParamsSchema = z.object({ pagination: z.number().optional() }).optional();
const yourLibrarySearchParamsSchema = z.object({ pagination: z.number().optional() }).optional();

const searchPageValues = [
  ...workSearchCategories,
  agentSearchCategory,
  ...yourLibrarySearchCategories,
] as const satisfies SearchCategory[];

const searchStateSchema = z
  .object({
    s: z.string().optional(),
    selectedCategory: z.enum(searchPageValues).optional(),
    work: workSearchParamsSchema,
    agent: agentSearchParamsSchema,
    yourLibrary: yourLibrarySearchParamsSchema,
  })
  .optional();

export type SearchState = z.infer<typeof searchStateSchema>;

export const useSearchState = () => useMultipleUrlStates(searchStateSchema);
