import { FrontendLocale } from "@libry-content/localization";
import { Site } from "@libry-content/types";
import { sift } from "radash";
import { useEffect } from "react";
import useSWRInfinite from "swr/infinite";
import { useCommonData } from "../../../../components/layout/CommonDataProvider";
import { useTranslation } from "../../../../utils/hooks/useTranslation";
import { SearchState } from "../useSearchState";
import ContentSearchResults from "./ContentSearchResults";
import { ContentSearchCategory, ContentSearchItem, searchContent } from "./searchers/searchContent";

type SwrKey = {
  query?: string;
  pageIndex?: number;
  pageSize: number;
  site?: Site;
  lang?: FrontendLocale;
  searchers: ContentSearchCategory[];
  includeCatogeries?: boolean;
} | null;

const getSwrKey =
  (config: Omit<NonNullable<SwrKey>, "pageIndex" | "previousPageData">) =>
  (
    pageIndex: number,
    previousPageData: { content: ContentSearchItem[]; endOfResults: boolean } | undefined
  ): SwrKey => {
    if (previousPageData && previousPageData?.endOfResults) return null;
    return { pageIndex, ...config }; // TODO, finn ut hva som gjør at det gjøres tre kall for hvert søk
  };

const fetcher = (key: SwrKey) => {
  if (!key?.site || !key.lang) return null;
  return searchContent({
    searchQuery: key?.query,
    siteDomain: key.site.domain,
    languageCode: key.lang,
    size: key.pageSize,
    from: key?.pageIndex ? key.pageIndex * key.pageSize : 0,
    searchers: key.searchers,
    includeCatogeries: key.includeCatogeries,
  });
};

type Props = {
  pageSize: number;
  searchers: ContentSearchCategory[];
  includeCatogeries?: boolean;
  searchState: SearchState;
  setSearchState: (searchState: NonNullable<SearchState>) => void;
};

export const useContentSearch = (props: Props) => {
  const { site } = useCommonData();
  const { lang } = useTranslation();

  const { data, setSize, isLoading, isValidating } = useSWRInfinite(
    getSwrKey({
      query: props.searchState?.s,
      site,
      lang,
      searchers: props.searchers,
      pageSize: props.pageSize,
      includeCatogeries: props.includeCatogeries,
    }),
    fetcher,
    {
      initialSize: props.searchState?.yourLibrary?.pagination ?? 1,
      revalidateOnFocus: false,
      revalidateIfStale: false,
    }
  );

  const items = (data && sift(data.map((result) => result?.items)).flatMap((items) => items)) ?? [];
  const endOfResults = !!data?.[data.length - 1]?.endOfResults;

  const categoryHits = data?.[0]?.categoryHits;
  const categories =
    categoryHits &&
    Object.entries(categoryHits)
      .filter(([, hits]) => !!hits)
      .map(([category]) => category as ContentSearchCategory);

  useEffect(() => {
    setSize(props.searchState?.yourLibrary?.pagination ? props.searchState?.yourLibrary?.pagination : 1);
  }, [setSize, props.searchState?.yourLibrary?.pagination]);

  const loadMoreResults = () => {
    const pagination = props.searchState?.yourLibrary?.pagination ? props.searchState.yourLibrary.pagination + 1 : 2;
    props.setSearchState({ yourLibrary: { pagination } });
  };

  const children = items.length ? (
    <ContentSearchResults
      items={items}
      endOfResults={endOfResults}
      isValidating={isValidating}
      paginate={loadMoreResults}
    />
  ) : null;

  return {
    children,
    isLoading,
    categories,
  };
};
