import { Box, Flex, Link, colors } from "@biblioteksentralen/react";
import { splashSiteUrl } from "@libry-content/common";
import { useTranslation } from "../../../utils/hooks/useTranslation";

function LibryContentLinks() {
  const { t } = useTranslation();

  return (
    <Flex background={colors.grey80} color="white" justifyContent="center" padding=".5rem">
      <Flex gap="0.5rem 1.5rem" justifyContent="center" opacity="0.6" flexWrap="wrap">
        <Box>
          {t("Bygget med")} {/* eslint-disable-next-line i18next/no-literal-string */}
          <Link href={splashSiteUrl.toString()} translate="no">
            Libry Content
          </Link>
        </Box>
        <Box>
          <Link href="/cms/" rel="nofollow">
            {t("Gå til redaktørverktøy")}
          </Link>
        </Box>
      </Flex>
    </Flex>
  );
}

export default LibryContentLinks;
