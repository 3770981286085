import { Language, Work } from "@biblioteksentralen/cordata";
import {
  Button,
  FormLabel,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
  colors,
} from "@biblioteksentralen/react";
import { sort } from "radash";
import { useRef } from "react";
import { ChevronDown } from "react-feather";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import {
  LanguagesSignature,
  formatLanguagesList,
  getLanguagesSignature,
  languageListsEqual,
} from "../../cordata/languages";
import { isMovie } from "../../cordata/workTypes";
import { OnSelectVariant } from "./VariantButton";
import { getDocumentTypeLabel, getDocumentTypes, getFormatOrderIndex } from "@libry-content/integrations";

const getExpressionsInLanguage = (work: Work, languagesSignature: LanguagesSignature | undefined) =>
  (work.expressions ?? []).filter(({ languages }) => getLanguagesSignature(languages) === languagesSignature);

export const getDocumentTypesInLanguage = (work: Work, languagesSignature: LanguagesSignature | undefined) =>
  sort(getDocumentTypes({ expressions: getExpressionsInLanguage(work, languagesSignature) }), ({ format }) =>
    getFormatOrderIndex(format)
  );

type ReservationLanguageMenuProps = {
  work: Work;
  isDisabled: boolean;
  languages: Language[][];
  currentLanguagesList: Language[] | undefined;
  onSelectVariant: OnSelectVariant;
};

export const ReservationLanguageMenu = ({
  work,
  isDisabled,
  languages,
  currentLanguagesList,
  onSelectVariant,
}: ReservationLanguageMenuProps) => {
  const { t } = useTranslation();
  const initialFocusRef = useRef(null);
  const currentLanguagesName = currentLanguagesList ? formatLanguagesList(currentLanguagesList) : undefined;
  const isMovieWork = isMovie(work);

  return (
    <>
      <FormLabel htmlFor="work-view-banner-language-picker" fontSize="sm" fontWeight="semibold" marginBottom="0.25rem">
        {t("Velg språk")}
        {":"}
      </FormLabel>
      <Menu id="work-view-banner-language-picker" initialFocusRef={initialFocusRef}>
        <MenuButton
          as={Button}
          aria-label={`${t("Velg språk")}. ${currentLanguagesName ?? "ingen"} ${t("er valgt")}.`}
          width="100%"
          rightIcon={<ChevronDown size="1em" />}
          background={colors.white}
          borderColor={colors.black}
          _hover={{ background: colors.grey10 }}
          color={colors.black}
          fontWeight="normal"
          isDisabled={isDisabled}
          _disabled={{ color: colors.grey30 }}
          textAlign="left"
          sx={{ span: { overflow: "hidden", textOverflow: "ellipsis" } }}
        >
          {currentLanguagesName}
        </MenuButton>
        {/* These props on MenuList make sure it's the same width as parent */}
        <MenuList minWidth="unset" width="100%" rootProps={{ width: "100%" }}>
          {languages.map((languagesList) => {
            const languagesSignature = getLanguagesSignature(languagesList);
            const languageNames = formatLanguagesList(languagesList);
            const documentTypes = getDocumentTypesInLanguage(work, languagesSignature).map(getDocumentTypeLabel);
            const ref = languageListsEqual(currentLanguagesList, languagesList) ? initialFocusRef : undefined;

            return (
              <MenuItem
                key={languagesSignature}
                _focus={{ background: colors.grey10 }}
                _hover={{ background: colors.grey10 }}
                border="2px solid transparent"
                _focusVisible={{ border: "2px solid rgba(66, 153, 225, 0.6)" }}
                onClick={() => onSelectVariant(languagesSignature, undefined)}
                isDisabled={isDisabled}
                ref={ref}
              >
                <VStack alignItems="flex-start" spacing="0">
                  <Text fontWeight={isMovieWork ? "normal" : "semibold"}>{languageNames}</Text>
                  {!isMovieWork && <Text fontSize="sm">{documentTypes.join(", ")}</Text>}
                </VStack>
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    </>
  );
};
