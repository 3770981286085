import { DocumentType, Work } from "@biblioteksentralen/cordata";
import { sift, sort, unique } from "radash";

export const getDocumentTypes = ({ expressions }: Pick<Work, "expressions">, catalogueId?: string): DocumentType[] =>
  unique(
    (expressions ?? []).flatMap(({ manifestations = [], aggregateManifestations = [] }) =>
      sift(
        [...manifestations, ...aggregateManifestations]
          .filter(
            ({ libraryCatalogueRecords }) =>
              !catalogueId ||
              libraryCatalogueRecords?.find(
                (libraryCatalogueRecord) => libraryCatalogueRecord.catalogueId === catalogueId
              )
          )
          .flatMap(({ documentType }) => documentType)
      )
    ),
    getDocumentTypeLabel
  );

export const getDocumentTypeLabel = ({ medium, format }: DocumentType): string => {
  if (medium === "strømningsbar video") return "Strømmevideo";
  if (medium && ["Film", "Musikk", "Dataspill"].includes(format)) return medium;
  if (medium === "kassett") return `Kassett ${format}`;
  return format;
};

export const getDocumentTypeLabels = (props: {
  work: Work;
  specifiedDocumentTypes?: DocumentType[];
  catalogueId?: string;
}) => {
  const illustratedTag = getIllustratedTag(props.work, props.catalogueId);
  const documentTypesSorted = sort(
    props.specifiedDocumentTypes ??
      getDocumentTypes(props.work, props.catalogueId).filter(
        ({ format }) => !illustratedTag || !formatsReplacedByIllustratedTag.includes(format)
      ),
    ({ format }) => getFormatOrderIndex(format)
  );
  const documentTypeLabels = sift([illustratedTag, ...documentTypesSorted.map(getDocumentTypeLabel)]);
  return documentTypeLabels;
};

const getIllustratedTag = (work: Work, catalogueId?: string) => {
  const documentTypes = getDocumentTypes(work, catalogueId);
  if (!documentTypes.some(({ format }) => ["bok", "ebok"].includes(format?.toLowerCase().replace("-", "")))) {
    return undefined;
  }

  const illustratedBookGenres = [
    "1166092", //bildebøker
    "1166093", //pekebøker
    "1103158", //Kunstnerbøker,
    "1453728", //Bilderomaner
  ];
  const comicGenres = [
    "1166096", //Tegneserier
    "1166043", //Grafiske romaner
  ];

  const illustratedWorkGenres = "1203040";

  if (
    work.genres?.some((genre) => {
      return illustratedBookGenres.includes(genre.identifiers.bibbiAuthorityId ?? "");
    })
  ) {
    return "Illustrert bok";
  }

  if (
    work.genres?.some((genre) => {
      return comicGenres.includes(genre.identifiers.bibbiAuthorityId ?? "");
    })
  ) {
    return "Tegneserie";
  }

  if (
    work.genres?.some((genre) => {
      return illustratedWorkGenres === genre.identifiers.bibbiAuthorityId;
    })
  ) {
    return "Illustrert verk";
  }

  return undefined;
};

const formatsReplacedByIllustratedTag = ["Bok"];

export const getFormatOrderIndex = (
  format: DocumentType["format"] | undefined,
  preferredFormatOrder = defaultFormatOrder
): number =>
  format && defaultFormatOrder.includes(format) ? preferredFormatOrder.indexOf(format) : preferredFormatOrder.length;

export const defaultFormatOrder = ["Bok", "E-bok", "Lydbok", "E-lydbok"];

export const getTranslatedDocumentTypeLabel = (documentTypeLabel: string, locale: "nb" | "nn") =>
  documentTypeLabelLanguageMap[documentTypeLabel.toLowerCase()]?.[locale] ?? documentTypeLabel;
const documentTypeLabelLanguageMap: Record<string, { nb: string; nn: string }> = {
  tegneserie: { nb: "tegneserie", nn: "teikneserie" },
  strømmevideo: { nb: "strømmevideo", nn: "straumevideo" },
};
