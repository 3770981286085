import { Button, ButtonProps } from "@biblioteksentralen/react";
import { MouseEventHandler } from "react";
import { Search } from "react-feather";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { headerLinkDecorationStyle } from "../utils";

type HeaderSearchButtonProps = { onClick: MouseEventHandler; searchIsOpen: boolean } & ButtonProps;

export const HeaderSearchButton = ({ onClick, searchIsOpen, ...buttonProps }: HeaderSearchButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button
      leftIcon={<Search size="1em" strokeWidth={2.2} />}
      variant="plain"
      onClick={onClick}
      aria-expanded={searchIsOpen}
      sx={headerLinkDecorationStyle(searchIsOpen)}
      {...buttonProps}
    >
      {t("Søk")}
    </Button>
  );
};
