import { Box, ChakraProps, Grid, Heading, LinkBox, Stack, Text } from "@biblioteksentralen/react";
import {
  isDigitalLibraryService,
  ResolvedEmployeeSummary,
  ResolvedListSummary,
  ResolvedRecommendationSummary,
} from "@libry-content/common";
import { ImageWithMetadata } from "@libry-content/types";
import { ReactNode, useEffect, useId, useRef } from "react";
import InternalLinkOverlay from "../../../components/InternalLinkOverlay";
import { EventPreview } from "../../../components/arrangement/EventsList";
import EmployeeAvatar from "../../../components/employees/EmployeeAvatar";
import SanityImage, { SanityImageProps } from "../../../components/images/SanityImage";
import CoverImageWithPlaceholder from "../../../components/lists/CoverImageWithPlaceholder";
import { BookListCoversFan } from "../../../components/lists/CoversFan";
import { OpeningHoursStatus } from "../../../components/openingHours/OpeningHoursStatus";
import { Teaser } from "../../../components/recommendation/Teaser";
import { getRecommendationPalette } from "../../../components/recommendation/utils";
import { logClick } from "../../../utils/analytics/Plausible";
import { getPath } from "../../../utils/getPath";
import { usePublicationLangAttribute } from "../../../utils/hooks/usePublicationLangAttribute";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { useSitePalette } from "../../../utils/useSitePalette";
import { SearchContentResponse } from "./content/searchers/searchContent";

type Content = SearchContentResponse["items"][number];
type Props = { item: Content; autoFocus?: boolean };

export const RenderContentPreview = ({ item, autoFocus }: Props) => {
  const { t, ts } = useTranslation();

  if (isDigitalLibraryService(item)) {
    return (
      <SimplePreviewLayout
        title={ts(item.name)}
        teaser={ts(item.teaser)}
        type={t("Digital tjeneste")}
        path={getPath(item)}
        sanityImage={item.image}
        autoFocus={autoFocus}
      />
    );
  }

  switch (item._type) {
    case "event":
    case "resolvedRepeatedEventOccurence":
      return (
        <EventPreview
          as="li"
          event={item}
          aboveHeading={<TypeLabel label={t("Arrangement")} marginBottom=".25em" />}
          hideBadges
          onClick={logContentClick}
          paddingY=".25rem"
          autoFocus={autoFocus}
        />
      );
    case "service":
      return (
        <SimplePreviewLayout
          sanityImage={item.image}
          path={getPath(item)}
          title={ts(item.title)}
          type={t("Tjeneste")}
          teaser={ts(item.teaser)}
          autoFocus={autoFocus}
        />
      );
    case "list":
      return <BookListPreview list={item} autoFocus={autoFocus} />;
    case "recommendation":
      return <RecommendationPreview recommendation={item} autoFocus={autoFocus} />;
    case "employee":
      return <EmployeePreview employee={item} autoFocus={autoFocus} />;
    case "staticPage":
      return (
        <SimplePreviewLayout sanityImage={item.image} path={getPath(item)} title={ts(item.title)} type={t("Info")} />
      );
    case "library":
      return (
        <SimplePreviewLayout
          sanityImage={item.image}
          sanityImageProps={{ aspectRatio: 0.9 }}
          path={getPath(item)}
          title={ts(item.name)}
          autoFocus={autoFocus}
          teaser={
            <Stack>
              <Text>{item.contactInfo?.visitingAddress?.streetAddress}</Text>
              <OpeningHoursStatus library={item} />
            </Stack>
          }
          type={t("Bibliotek")}
        />
      );
    default:
      console.error(`Unknown content type in search results: "${item._type}"`);
      return null;
  }
};

const SimplePreviewLayout = (props: {
  sanityImage?: ImageWithMetadata;
  sanityImageProps?: Partial<SanityImageProps>;
  customImage?: ReactNode;
  title?: string;
  type: string;
  teaser?: string | ReactNode;
  path: string;
  autoFocus?: boolean;
}) => {
  const headingId = useId();

  const ref = useRef<HTMLAnchorElement>(null);
  useEffect(() => {
    props.autoFocus && ref.current?.focus();
  }, [props.autoFocus]);

  return (
    <LinkBox as="li" _hover={{ background: "rgba(0,0,0,0.035)" }} aria-labelledby={headingId} paddingY=".75rem">
      <Grid gap=".75rem" role="group" gridTemplateColumns="7.5rem 1fr">
        {props.sanityImage?.asset ? (
          <SanityImage
            imageWrapperProps={{ height: "fit-content" }}
            image={props.sanityImage}
            resolution={200}
            aspectRatio={1.25}
            hideCaption
            {...props.sanityImageProps}
          />
        ) : (
          props.customImage
        )}
        <Stack gridColumn="2/3">
          <TypeLabel label={props.type} />
          <InternalLinkOverlay onClick={logContentClick} href={props.path} ref={ref}>
            <Heading _groupHover={{ textDecoration: "underline" }} as="h3" size="md" id={headingId}>
              {props.title}
            </Heading>
          </InternalLinkOverlay>
          <Box>{props.teaser}</Box>
        </Stack>
      </Grid>
    </LinkBox>
  );
};

const BookListPreview = ({ list, autoFocus }: { list: ResolvedListSummary; autoFocus?: boolean }) => {
  const { t, ts } = useTranslation();
  const paletteColor = useSitePalette();

  return (
    <SimplePreviewLayout
      customImage={
        <BookListCoversFan
          {...paletteColor.colors.card.css}
          borderRadius="md"
          publications={list.publications}
          maxWidth="7.5rem"
          minHeight="6rem"
        />
      }
      path={getPath(list)}
      title={ts(list.title)}
      type={t("Liste")}
      teaser={ts(list.teaser)}
      autoFocus={autoFocus}
    />
  );
};

const RecommendationPreview = ({
  recommendation,
  autoFocus,
}: {
  recommendation: ResolvedRecommendationSummary;
  autoFocus?: boolean;
}) => {
  const { t } = useTranslation();
  const publicationLangAttribute = usePublicationLangAttribute(recommendation.publication);
  const palette = getRecommendationPalette(recommendation);
  const headingId = useId();

  const ref = useRef<HTMLAnchorElement>(null);
  useEffect(() => {
    autoFocus && ref.current?.focus();
  }, [autoFocus]);

  const { publication } = recommendation;

  if (!publication) return null;

  const title = publication.title?.split(":")[0];

  return (
    <LinkBox as="li">
      <Stack role="group" _hover={{ background: "rgba(0,0,0,0.035)" }} aria-labelledby={headingId} paddingY=".75rem">
        <Grid gridTemplateColumns="auto 1fr" gridTemplateRows="2.5rem auto" maxWidth="22rem">
          <Box gridColumn="1/3" gridRow="2/3" background={palette.mainPalette.backgroundColor} borderRadius=".5rem" />
          <CoverImageWithPlaceholder
            publication={publication}
            width="5rem"
            gridColumn="1/2"
            gridRow="1/3"
            margin="0 .5rem .75rem 1rem"
          />
          <TypeLabel
            label={t("Anbefaling")}
            gridColumn="2/3"
            gridRow="1/2"
            alignSelf="end"
            justifySelf="start"
            marginBottom=".5rem"
          />
          <Teaser
            gridColumn="2/3"
            gridRow="2/3"
            padding=".75rem"
            fontSize="xs"
            noOfLines={4}
            recommendation={recommendation}
            quoteMarkProps={{ ...palette.quoteMarkProps, marginRight: "-.25em" }}
            {...palette.teaserTextProps}
          />
        </Grid>
        <Box lang={publicationLangAttribute}>
          <InternalLinkOverlay
            onClick={logContentClick}
            href={getPath(recommendation)}
            _groupHover={{ textDecoration: "underline" }}
            ref={ref}
          >
            <Heading as="h3" size="md" noOfLines={2} id={headingId}>
              {title}
            </Heading>
          </InternalLinkOverlay>
          <Text fontSize="sm">{publication.author}</Text>
        </Box>
      </Stack>
    </LinkBox>
  );
};

const EmployeePreview = ({ employee, autoFocus }: { employee: ResolvedEmployeeSummary; autoFocus?: boolean }) => {
  const { t, ts } = useTranslation();

  if (!employee || !employee.showEmployee) {
    return null;
  }
  return (
    <SimplePreviewLayout
      customImage={<EmployeeAvatar employee={employee} size="7.5rem" resolution={300} fontSize="xl" hideCaption />}
      type={t("Ansatt")}
      title={employee.name}
      path={getPath(employee)}
      autoFocus={autoFocus}
      teaser={
        <>
          <Text>{ts(employee.position)}</Text>
          {employee.affiliations?.map((affiliation) => (
            <Text key={affiliation.entity?._id}>{ts(affiliation.entity?.name)}</Text>
          ))}
        </>
      }
    />
  );
};

const TypeLabel = ({ label, ...chakraProps }: { label: string } & ChakraProps) => {
  const paletteColor = useSitePalette().colors.lightaccent1;

  return (
    <Box
      {...paletteColor.css}
      fontSize="xs"
      fontWeight={600}
      borderRadius="sm"
      padding=".1em .5em"
      alignSelf="flex-start"
      {...chakraProps}
    >
      {label}
    </Box>
  );
};

const logContentClick = () => logClick("Søk: Klikk på treff i Ditt Bibliotek");
