import { Button, ButtonProps, Spinner } from "@biblioteksentralen/react";
import { Plus } from "react-feather";
import { useTranslation } from "../../../utils/hooks/useTranslation";

type Props = {
  loading: boolean;
} & ButtonProps;

export const SeeMoreResultsButton = ({ loading, ...buttonProps }: Props) => {
  const { t } = useTranslation();

  return (
    <Button
      leftIcon={<Plus size="1em" />}
      variant="secondary"
      isDisabled={loading}
      textDecoration="none"
      size="lg"
      {...buttonProps}
    >
      {t("Vis flere")}
      {loading && <Spinner size="sm" marginLeft="0.5rem" />}
    </Button>
  );
};
