import { Flex, HStack, Stack } from "@biblioteksentralen/react";
import { usePathname } from "next/navigation";
import { HeaderFooterContainer } from "../../ContentContainer";
import InternalLink from "../../InternalLink";
import { headerLinkDecorationStyle } from "../utils";
import { HeaderSearchButton } from "./HeaderSearchButton";
import { HeaderUserInfo } from "./HeaderUserInfo";
import Logo from "./Logo";
import { MinSideLink } from "./MinSideLink";
import { useHeaderNavigationLinks } from "./useHeaderNavigationLinks";
import { useSearchInput } from "./useSearchInput";
import { useIsFrontPage } from "../../../utils/hooks/useIsFrontPage";

export const Desktop = () => {
  const navigationLinks = useHeaderNavigationLinks();
  const pathname = usePathname();
  const isFrontPage = useIsFrontPage();

  const {
    isOpen: searchIsOpen,
    toggle: toggleSearchIsOpen,
    input: searchInput,
  } = useSearchInput({ defaultOpen: isFrontPage });

  return (
    <>
      <HeaderFooterContainer as="nav">
        <Stack paddingY="1.5rem" spacing="1.5rem">
          <Flex gap="4rem" justifyContent="space-between" flexWrap="wrap">
            <Logo />
            <HeaderUserInfo />
          </Flex>
          <Flex gap="4rem" justifyContent="space-between" flexWrap="wrap">
            <HStack
              as="ul"
              listStyleType="none"
              spacing={{ base: "2rem", xl: "2.5rem" }}
              flexWrap="wrap"
              justifyContent="center"
            >
              {navigationLinks.map((link) => (
                <li key={link.path}>
                  <InternalLink
                    href={link.path}
                    variant="plain"
                    whiteSpace="nowrap"
                    fontWeight="600"
                    sx={headerLinkDecorationStyle(pathname?.startsWith(link.path))}
                  >
                    {link.title}
                  </InternalLink>
                </li>
              ))}
            </HStack>
            <HStack>
              <HeaderSearchButton searchIsOpen={searchIsOpen} onClick={toggleSearchIsOpen} />
              <MinSideLink sx={headerLinkDecorationStyle()} />
            </HStack>
          </Flex>
        </Stack>
      </HeaderFooterContainer>
      {searchInput}
    </>
  );
};
