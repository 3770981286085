import { DocumentType, Language, Platform } from "@biblioteksentralen/cordata";
import { Box, BoxProps, Flex, FormLabel, HStack, Text, VStack } from "@biblioteksentralen/react";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { formatLanguagesList, getLanguagesSignature, languageListsEqual } from "../../cordata/languages";
import { reservationFullWidthBreakpoint } from "./ReservationContainer";
import { OnSelectVariant, VariantButton } from "./VariantButton";
import { ExternalContentService } from "./externalContentService";
import { getDocumentTypeLabel } from "@libry-content/integrations";

export type WorkVariant = {
  documentType: DocumentType;
  languagesList?: Language[];
  platforms: Platform[] | undefined;
  externalContentService?: ExternalContentService | undefined;
};

type ReservationVariantButtonsProps = {
  isDisabled: boolean;
  useLanguageMenu: boolean;
  currentLanguagesList: Language[] | undefined;
  currentDocumentType: DocumentType | undefined;
  onSelectVariant: OnSelectVariant;
  workVariants: WorkVariant[];
};

export const ReservationVariantButtons = ({
  isDisabled,
  useLanguageMenu,
  currentLanguagesList,
  currentDocumentType,
  onSelectVariant,
  workVariants,
  ...boxProps
}: ReservationVariantButtonsProps & BoxProps) => {
  const { t } = useTranslation();

  const everyVariantIsVideoGame = workVariants.every(({ documentType }) => documentType.format === "Dataspill");
  const everyVariantIsMovie = workVariants.every(({ documentType }) => documentType.format === "Film");
  const everyVariantLanguageIsEqual = workVariants
    .map(({ languagesList }) => (languagesList ? getLanguagesSignature(languagesList) : undefined))
    .every((languagesSignature, index, languagesSignatureArray) => languagesSignature === languagesSignatureArray[0]);

  // if variants are not all movies and not all video games, and all variants are not of same language, show language in button
  const includeLanguageInVariantButtons =
    !everyVariantIsMovie && !everyVariantIsVideoGame && !everyVariantLanguageIsEqual;

  if (workVariants.length === 1 && workVariants[0]) {
    return (
      <VStack spacing="0.25rem" alignItems="flex-start" {...boxProps}>
        <Text fontSize="sm" fontWeight="semibold" margin="1rem 0 0">
          {!currentLanguagesList?.length || useLanguageMenu || includeLanguageInVariantButtons
            ? t("Tilgjengelig som")
            : t("Tilgjengelig på {language}", {
                language: formatLanguagesList(currentLanguagesList ?? []),
              })}
          {":"}
        </Text>
        <VariantButton
          workVariant={workVariants[0]}
          onSelectVariant={onSelectVariant}
          isSelected
          includeLanguageInButton={includeLanguageInVariantButtons}
        />
      </VStack>
    );
  }

  return (
    <Flex flexDirection="column" alignItems="flex-start" justifyContent="center" marginTop="0.5rem" {...boxProps}>
      <Box>
        <FormLabel
          htmlFor="work-view-banner-format-picker"
          id="work-view-banner-format-picker-id"
          fontSize="sm"
          fontWeight="semibold"
          marginBottom="0.25rem"
          margin={{ base: "0.5rem auto 0.25rem", [reservationFullWidthBreakpoint]: "1rem auto 0.25rem" }}
        >
          {t("Velg format")}
          {":"}
        </FormLabel>
        <HStack
          id="work-view-banner-format-picker"
          role="radiogroup"
          aria-labelledby="work-view-banner-format-picker-id"
          flexWrap="wrap"
          spacing="0.75rem"
          alignItems="center"
          justifyContent="center"
        >
          {workVariants.map((workVariant) => (
            <VariantButton
              key={`${getDocumentTypeLabel(workVariant.documentType)}${
                useLanguageMenu && workVariant.languagesList
                  ? `-${getLanguagesSignature(workVariant.languagesList)}`
                  : ""
              }`}
              workVariant={workVariant}
              includeLanguageInButton={
                includeLanguageInVariantButtons && workVariant.documentType.format !== "Dataspill"
              }
              onSelectVariant={onSelectVariant}
              isDisabled={isDisabled}
              isSelected={
                !!currentDocumentType &&
                getDocumentTypeLabel(currentDocumentType) === getDocumentTypeLabel(workVariant.documentType) &&
                (useLanguageMenu ||
                  !workVariant.languagesList ||
                  languageListsEqual(workVariant.languagesList, currentLanguagesList))
              }
            />
          ))}
        </HStack>
      </Box>
    </Flex>
  );
};
